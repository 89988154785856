<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal
        id="approvalModal"
        size="lg"
        title="Confirmer l'approbation"
        title-class="font-18"
        hide-footer
      >
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="formrow-inputCity">Commentaires </label>
                <textarea
                  v-model="approvalForm.comments"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-primary float-right" type="submit">
                <i class="fas fa-check"></i>
                Approuver
              </button>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: {
    modelRef: {
      type: String,
    },
    modelId: {
      type: Number,
    },

    modelType: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      approvalForm: {
        modelId: "",
        modelType: "",
        comments: "",
      },
    };
  },
  methods: {
    submit() {
      Swal.fire({
        title:
          "Êtes-vous sûr de valider l'opération: " + this.$props.modelRef + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Approuver!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/ops/decisions/approve", {
              modelId: this.$props.modelId,
              modelType: this.$props.modelType,
              comments: this.approvalForm.comments,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.msg);
                  window.location.reload();
                  break;

                case 500:
                  this.$toast.warning(res.data.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {
              loader.hide();

            });
        }
      });
    },
  },
};
</script>
